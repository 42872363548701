<template>
  <div
    class="d-flex flex-column pa-3"
    style="
      gap: 16px;
      border-radius: 8px;
      border: 1px solid var(--v-background_dark-base);
    "
  >
    <div class="d-flex justify-space-between">
      <div class="title-2 font-weight-bold onSurfae_dark--text">고객 채팅</div>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        x-small
        @click="display = !display"
      >
        <feather :type="display ? 'chevron-up' : 'chevron-down'" />
      </v-btn>
    </div>
    <div class="py-1 px-2 d-flex justify-space-between">
      <div class="d-flex" style="gap: 8px">
        <feather type="users" size="20" />
        <div
          class="subtitle-1 font-weight-bold onSurface_normal--text d-flex align-center"
        >
          담당자
        </div>
      </div>
      <div
        class="subtitle-1 font-weight-regular onSurface_normal--text d-flex align-center"
      >
        자체 기능 개발 전
      </div>
    </div>
    <div
      class="d-flex flex-column overflow-y-auto"
      ref="chatMsgCard"
      style="height: 380px"
    >
      <div v-for="(message, index) in messages" :key="index">
        <div
          class="d-flex pb-3"
          style="gap: 8px; width: 100%"
          :class="message.personType != 'user' ? 'pl-5 justify-end' : 'pr-5'"
        >
          <div v-if="message.personType == 'user'">
            <div
              style="
                width: 32px;
                height: 32px;
                background: lightgreen;
                border-radius: 12px;
              "
            />
          </div>
          <div class="d-flex flex-column" style="gap: 4px">
            <!-- 메세지 박스 -->
            <div
              class="pa-2"
              style="
                border-radius: 8px;
                background: var(--v-background_normal-base);
              "
              :style="
                message.options?.includes('private') && 'background: #E3C469'
              "
            >
              <span
                class="body-2 onSurface_dark--text"
                v-html="message.plainText?.replace(/\n/g, '<br />')"
              >
              </span>
            </div>
            <!-- 메세지 전송 시간 -->
            <div
              class="caption px-1 onSurface_light--text"
              :class="message.personType != 'user' ? 'text-right' : 'text-left'"
            >
              {{ $moment(message.createdAt).format("MM/DD HH:mm") }}
            </div>
          </div>
          <div v-if="message.personType != 'user'">
            <div
              style="
                width: 32px;
                height: 32px;
                background: skyblue;
                border-radius: 12px;
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column" style="gap: 4px">
      <div>이미지</div>
      <v-textarea
        outlined
        v-model="sendMsgValue"
        label="고객에게 메세지를 보내보세요. (준비중)"
        rows="3"
        hide-details
        single-line
        style="width: 100%"
      />
      <div class="d-flex justify-space-between align-center">
        <v-btn icon x-small max-height="28px" width="28px">
          <feather type="paperclip" size="20" />
        </v-btn>
        <v-btn color="primary" elevation="0" max-height="28px" min-width="52px">
          <feather type="send" size="16" />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-ChatCard",
  props: { order: Object },
  data() {
    return {
      display: true,
      messages: [],
      sendMsgValue: null,
    };
  },
  watch: {
    order: {
      deep: true,
      immediate: true,
      handler(n, o) {
        if (!n.id || n?.id === o?.id) return;
        this.getChannelMsg();
      },
    },
  },
  methods: {
    async getChannelMsg() {
      this.messages = [];
      if (
        this.order.channelTalk &&
        this.order.channelTalk.chat &&
        this.order.channelTalk.chat.id
      ) {
        const t = await this.$axios.patch(
          "channelTalk/getMsg/" + this.order.channelTalk.chat.id
        );
        this.messages = t.data;
        this.$nextTick(() => {
          this.$refs.chatMsgCard.scrollTop =
            this.$refs.chatMsgCard.scrollHeight;
        });
      }
    },
  },
};
</script>

<style scoped></style>
