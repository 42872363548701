<template>
  <div
    class="d-flex flex-column pa-3"
    style="
      gap: 12px;
      border-radius: 8px;
      border: 1px solid var(--v-background_dark-base);
      width: 100%;
    "
  >
    <div class="d-flex justify-space-between">
      <div class="title-2 font-weight-bold onSurfae_dark--text">
        주문자 정보
      </div>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        x-small
        @click="display = !display"
      >
        <feather :type="display ? 'chevron-up' : 'chevron-down'" />
      </v-btn>
    </div>
    <div v-if="display" class="d-flex flex-column" style="gap: 4px">
      <div
        v-for="item in items"
        :key="item.title"
        class="py-1 px-2 d-flex justify-space-between"
      >
        <div class="d-flex" style="gap: 8px">
          <feather :type="item.icon" size="20" />
          <div
            class="subtitle-1 font-weight-bold onSurface_normal--text d-flex align-center"
          >
            {{ item.title }}
          </div>
        </div>
        <div
          class="subtitle-1 font-weight-regular onSurface_normal--text d-flex align-center overflow-hidden"
          style="max-width: 200px"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
    <div v-if="display" class="d-flex flex-column" style="gap: 4px">
      <div v-for="order in orders" :key="order.id">
        <v-hover v-slot="{ hover }" close-delay="10">
          <div
            class="d-flex px-2 py-1 justify-space-between align-center"
            style="width: 100%; border-radius: 4px"
            :style="hover && 'background: var(--v-background_normal-base)'"
            @click="navigateToOrder(order.id)"
          >
            <div
              class="d-flex flex-column onSurface_normal--text"
              style="gap: 4px"
            >
              <div class="subtitle-2">
                {{ order.createdAt | moment("YYYY.MM.DD") }}
              </div>
              <div class="subtitle-1 font-weight-bold">
                {{ order.purpose }}
              </div>
            </div>
            <div
              class="d-flex flex-column onSurface_normal--text"
              style="gap: 4px"
            >
              <div class="subtitle-2 justify-end d-flex">
                {{ order.workspace }}
              </div>
              <div class="subtitle-1 font-weight-bold justify-end d-flex">
                {{ order.totalPrice?.toLocaleString() + "원" }}
              </div>
            </div>
          </div>
        </v-hover>
      </div>
      <div
        class="align-center justify-center d-flex subtitle-1 font-weight-regular onSurface_normal--text"
        style="
          background: var(--v-background_normal-base);
          border-radius: 4px;
          width: 100%;
          height: 25px;
        "
        @click="$router.push('/admin/users')"
      >
        프로필에서 더보기
      </div>
    </div>
  </div>
</template>

<script>
import ORDER from "@/API/order.js"; // order.js의 searchOrder 함수 import
export default {
  name: "Admin-Order-CustomerCard",
  props: { order: Object },
  data() {
    return {
      display: true,
      items: [],
      orders: [],
    };
  },
  watch: {
    order: {
      deep: true,
      immediate: true,
      handler(n, o) {
        if (n?.id === o?.id) return;
        this.updateItems();
        this.searchUserOrder();
      },
    },
  },
  mounted() {
    this.searchUserOrder();
  },
  methods: {
    async searchUserOrder() {
      if (!this.order?.uid) return;

      // searchOrder 호출
      ORDER.searchOrder(
        { field: "uid", value: this.order.uid, limit: 3 },
        (orders) => {
          // 현재 주문 제외 및 최대 2개의 주문만 표시
          this.orders = orders
            .filter((item) => item.id !== this.order.id) // 현재 주문 제외
            .slice(0, 2); // 최대 2개로 제한
        }
      );
    },
    updateItems() {
      this.items = [
        {
          icon: "user",
          title: "UID",
          value: this.order.uid || "-",
        },
        {
          icon: "smartphone",
          title: "연락처",
          value:
            this.order.phoneNumber?.replace(
              /^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
              "$1-$2-$3"
            ) || "-",
        },
      ];
    },
    navigateToOrder(orderId) {
      if (this.$route.query.id !== orderId) {
        this.$router.push({
          path: this.$route.path,
          query: { ...this.$route.query, id: orderId },
        });
      }
    },
  },
};
</script>

<style></style>
