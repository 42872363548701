<template>
  <div
    class="d-flex flex-column"
    style="
      height: 100%;
      border-bottom: 1px solid var(--v-background_dark-base);
      width: 100%;
      overflow-y: auto;
    "
  >
    <div class="d-flex pa-5 justify-space-between">
      <div class="d-flex flex-column" style="gap: 4px">
        <div class="title-1 font-weight-bold onSurface_dark--text">
          {{ order.displayName }}
        </div>
        <div
          class="subtitle-1 font-weight-regular onSurface_dark--text"
          style="white-space: nowrap; width: fit-content"
        >
          {{ order.updatedAt | moment("YYYY-MM-DD hh:mm") }} 수정된 주문
        </div>
      </div>
      <div class="d-flex align-center" style="gap: 24px">
        <v-btn
          icon
          x-small
          :disabled="!saveBtn"
          @click="saveOrder"
          :loading="saveBtnLoading"
        >
          <feather
            type="save"
            :stroke="
              saveBtn
                ? 'var(--v-onSurface_dark-base)'
                : 'var(--v-background_dark-base)'
            "
            size="24"
          />
        </v-btn>
        <v-btn
          color="primary"
          max-height="40"
          elevation="0"
          class="subtitle-1 font-weight-bold"
          @click="btnClick()"
          :loading="btnLoading"
        >
          {{
            order.status === "Pending"
              ? "상담 시작"
              : order.status === "Checking"
              ? "결제 확인"
              : order.status === "Ready"
              ? "작업 시작"
              : order.status === "Progress"
              ? "작업 완료"
              : order.status === "Finished"
              ? "출고 처리"
              : order.status === "Shipped"
              ? "상담 완료"
              : "메세지 전송"
          }}
        </v-btn>
      </div>
    </div>

    <div class="d-flex">
      <div class="d-flex flex-column px-5 pb-10" style="gap: 20px; width: 100%">
        <div class="d-flex" style="gap: 20px">
          <customer-card-vue :order="order" />
          <payment-card-vue
            :order="editedOrder"
            @update="updateValue($event)"
            @saveOrder="saveOrder()"
          />
        </div>
        <modeling-card-vue
          :order="editedOrder"
          v-if="order.modelingFile?.length > 0"
        />
        <printing-card-vue
          :order="editedOrder"
          @update="updateValue($event)"
          @saveOrder="saveOrder()"
        />
        <result-card-vue :order="editedOrder" />
      </div>
      <div
        class="d-flex flex-column pr-5 pb-10"
        style="gap: 20px; min-width: 360px; width: 380px"
      >
        <data-card-vue :order="editedOrder" @update="updateValue($event)" />
        <chat-card-vue :order="editedOrder" />
        <message-card-vue :order="editedOrder" />
        <v-btn
          color="error"
          elevation="0"
          class="subtitle-1 font-weight-bold"
          :loading="deleteBtnLoading"
          @click="
            deleteBtnLoading = true;
            alert = {
              dialog: true,
              msg: '해당 기능은 되돌릴 수 없습니다. \n 주문서가 완전히 삭제됩니다.',
              confirmMsg: '삭제',
              cancelMsg: '취소',
              onConfirm: () => {
                $emit('deleteOrder', order);
                deleteBtnLoading = false;
              },
              onCancel: () => {
                deleteBtnLoading = false;
              },
            };
          "
        >
          주문서 영구 삭제
        </v-btn>
      </div>
    </div>
    <WarningDialog
      :msg="alert"
      :visible.sync="alert.dialog"
      @confirm="alert.onConfirm"
      @cancel="alert.onCancel"
    />
  </div>
</template>

<script>
import _ from "lodash";
import ORDER from "@/API/order.js";

import CustomerCardVue from "./CustomerCard.vue";
import PaymentCardVue from "./PaymentCard.vue";
import ModelingCardVue from "./ModelingCard.vue";
import PrintingCardVue from "./PrintingCard.vue";
import ResultCardVue from "./ResultCard.vue";
import DataCardVue from "./DataCard.vue";
import ChatCardVue from "./ChatCard.vue";
import MessageCardVue from "./MessageCard.vue";

export default {
  name: "Admin-Order-DataSection",
  components: {
    CustomerCardVue,
    PaymentCardVue,
    ModelingCardVue,
    PrintingCardVue,
    ResultCardVue,
    DataCardVue,
    ChatCardVue,
    MessageCardVue,
  },
  props: { order: Object },
  data() {
    return {
      saveBtnLoading: false,
      btnLoading: false,
      alert: {
        dialog: false,
        msg: "",
        confirmMsg: "",
        cancelMsg: "",
        onConfirm: () => {},
        onCancel: () => {},
      },
      deleteBtnLoading: false,
      saveBtn: false,
      editedOrder: {},
      sendReviewRequest: false,
    };
  },
  watch: {
    order: {
      deep: true,
      immediate: true,
      handler(n, o) {
        if (n?.id !== o?.id) {
          this.editedOrder = _.cloneDeep(this.order);
          this.editedOrder?.file?.forEach((file, index) => {
            file.selected = index === 0;
            file.option.forEach((option, idx) => (option.selected = idx === 0));
          });
        }
        this.checkSaveBtn();
      },
    },
    editedOrder: {
      deep: true,
      immediate: true,
      handler() {
        this.editedOrder.totalPrice =
          (Number(this.editedOrder.modelingPrice) || 0) +
          (Number(this.editedOrder.printingPrice) || 0) +
          (Number(this.editedOrder.shippingPrice) || 0) +
          (Number(this.editedOrder.discountPrice) || 0);
        this.checkSaveBtn();
      },
    },
  },
  methods: {
    updateValue(o) {
      this.editedOrder[o.field] = o.value;
    },
    async saveOrder() {
      this.saveBtnLoading = true;
      try {
        if (["Pending", "Checking", "Hold"].includes(this.editedOrder.status)) {
          this.editedOrder.paidAt = null;
        } else if (
          this.editedOrder.status === "Ready" &&
          ["Pending", "Checking", "Hold"].includes(this.order.status)
        ) {
          this.editedOrder.paidAt = new Date();
        }
        if (
          this.editedOrder.status !== "Shipped" &&
          this.order.status === "Shipped"
        ) {
          this.editedOrder.shipAt = null;
        }
        if (
          this.editedOrder.status === "Shipped" &&
          this.order.status === "Finished"
        ) {
          this.editedOrder.shipAt = new Date();
        }

        await ORDER.updateOrder(this.editedOrder);
        this.checkSaveBtn();
      } catch (error) {
        console.error("Error updating order:", error);
        this.$toasted.global.error("주문서 저장 중 오류가 발생했습니다.");
      } finally {
        this.saveBtnLoading = false;
      }
    },
    checkSaveBtn() {
      const editValue = _.cloneDeep(this.editedOrder);
      editValue.file?.forEach((file) => {
        delete file.selected;
        file.option.forEach((option) => delete option.selected);
      });
      this.saveBtn = !_.isEqual(this.order, editValue); // Lodash `_.differenceWith`를 활용해 차이점 분석
    },
    async btnClick() {
      this.btnLoading = true;
      if (this.order.status === "Pending") {
        try {
          const response = await this.$axios.post("notice/orderCheckStart", {
            order: this.editedOrder,
            admin: this.$store.getters["auth/user/GET_USER"].displayName,
          });
          // 메시지 발송이 성공했을 때만 안내 문구를 표시
          if (response.status === 200) {
            this.$toasted.global.notice(
              "고객에게 상담 시작 메세지가 전송되었습니다."
            );
            this.editedOrder.status = "Checking";
            this.saveOrder();
          } else {
            this.$toasted.global.error(
              "메세지 전송에 실패했습니다. 다시 시도해주세요."
            );
          }
        } catch (error) {
          console.error("메시지 발송 중 오류:", error);
          this.$toasted.global.error(
            "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      } else if (this.order.status === "Checking") {
        try {
          if (!this.order.paymentMethod)
            return this.$toasted.global.error(
              "결제 방식을 먼저 선택해 주세요!"
            );
          if (!this.order.shippingMethod)
            return this.$toasted.global.error(
              "배송 방식을 먼저 선택해 주세요!"
            );
          await this.$axios.post("notice/orderPaid", this.editedOrder);
          this.editedOrder.status = "Ready";
          this.saveOrder();
        } catch (error) {
          console.error("메시지 발송 중 오류:", error);
          this.$toasted.global.error(
            "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      } else if (this.order.status === "Ready") {
        try {
          await this.$axios.post("/notice/orderStart", this.editedOrder);
          this.$toasted.global.notice(
            "고객님께 작업 시작 메세지가 전송되었습니다"
          );
          this.editedOrder.status = "Progress";
          this.saveOrder();
        } catch (error) {
          console.error("메시지 발송 중 오류:", error);
          this.$toasted.global.error(
            "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      } else if (this.order.status === "Progress") {
        try {
          await this.$axios.post("/notice/orderFinished", this.editedOrder);
          this.$toasted.global.notice(
            "고객님께 완료 안내 메세지가 전송되었습니다"
          );
          this.editedOrder.status = "Finished";
          this.saveOrder();
        } catch (error) {
          console.error("메시지 발송 중 오류:", error);
          this.$toasted.global.error(
            "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      } else if (this.order.status === "Finished") {
        try {
          if (
            this.editedOrder.shippingMethod == "택배" &&
            (this.editedOrder.paymentMethod == "현금 결제" ||
              this.editedOrder.paymentMethod == "세금계산서") &&
            !this.editedOrder.trackingCode
          )
            return this.$toasted.global.error("운송장 번호가 없습니다.");
          await this.$axios.post("/notice/orderShipped", this.editedOrder);
          this.$toasted.global.notice(
            "고객님께 출고 안내 메세지가 전송되었습니다"
          );
          this.editedOrder.status = "Shipped";
          this.saveOrder();
        } catch (error) {
          console.error("메시지 발송 중 오류:", error);
          this.$toasted.global.error(
            "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      } else if (this.order.status === "Shipped") {
        try {
          this.sendReviewRequest = true;
          await this.$axios.post("notice/orderComplete", {
            order: this.editedOrder,
            sendReviewRequest: this.sendReviewRequest,
          });
          this.editedOrder.status = "Completed";
          this.saveOrder();
        } catch (error) {
          console.error("메시지 발송 중 오류:", error);
          this.$toasted.global.error(
            "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
      this.btnLoading = false;
    },
  },
};
</script>

<style></style>
