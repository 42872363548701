<template>
  <div
    class="d-flex flex-column pa-3"
    style="
      gap: 12px;
      border-radius: 8px;
      border: 1px solid var(--v-background_dark-base);
    "
  >
    <div class="d-flex justify-space-between">
      <div class="title-2 font-weight-bold onSurfae_dark--text">
        메세지 발송 내역
      </div>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        x-small
        @click="display = !display"
      >
        <feather :type="display ? 'chevron-up' : 'chevron-down'" />
      </v-btn>
    </div>
    <div class="d-flex justify-center align-center" style="height: 180px">
      이곳에서 발송 메세지 내역을 볼 수 있습니다.
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-MessageCard",
  props: { order: Object },
  data() {
    return {
      display: true,
    };
  },
  methods: {},
};
</script>

<style></style>
