import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { OBJExporter } from "three/examples/jsm/exporters/OBJExporter";
import { ThreeMFLoader } from "three/examples/jsm/loaders/3MFLoader";

async function loadGeometryFromFile(file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", file.url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const fileName = file.name.toLowerCase();
        if (fileName.endsWith(".stl")) {
          loadSTL(blob).then(resolve).catch(reject);
        } else if (fileName.endsWith(".obj")) {
          loadOBJ(blob).then(resolve).catch(reject);
        } else if (fileName.endsWith(".3mf")) {
          load3MF(blob).then(resolve).catch(reject);
        } else if (fileName.endsWith(".stp") || fileName.endsWith(".step")) {
          console.log("API - STEP");
          // loadSTEP(blob).then(resolve).catch(reject);
        } else {
          console.error("Unsupported file format.");
          reject(new Error("Unsupported file format."));
        }
      } else {
        reject(new Error("Failed to load file."));
      }
    };
    xhr.onerror = () => {
      console.error("File download failed.");
      reject(new Error("File download failed."));
    };
    xhr.send();
  });
}

function loadSTL(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const loader = new STLLoader();
        const geometry = loader.parse(event.target.result);
        resolve(geometry);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

function loadOBJ(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const loader = new OBJLoader();
        const object = loader.parse(event.target.result);
        object.traverse((child) => {
          if (child.isMesh) {
            resolve(child.geometry);
          }
        });
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = reject;
    reader.readAsText(blob);
  });
}

function load3MF(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const loader = new ThreeMFLoader();
        const object = loader.parse(event.target.result);
        object.children.forEach((child) => {
          child.children.forEach((mesh) => {
            if (mesh.isMesh) {
              resolve(mesh.geometry);
            }
          });
        });
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

export async function getModelDimensions(file) {
  const geometry = await loadGeometryFromFile(file);
  const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
  const size = box.getSize(new THREE.Vector3());
  return {
    x: size.x.toFixed(2),
    y: size.y.toFixed(2),
    z: size.z.toFixed(2),
  };
}

function signedVolumeOfTriangle(p1, p2, p3) {
  return p1.dot(p2.cross(p3)) / 6.0;
}

export async function getModelVolume(file) {
  const geometry = await loadGeometryFromFile(file);
  let position = geometry.attributes.position;
  let faces = position.count / 3;
  let sum = 0;
  let p1 = new THREE.Vector3(),
    p2 = new THREE.Vector3(),
    p3 = new THREE.Vector3();
  for (let i = 0; i < faces; i++) {
    p1.fromBufferAttribute(position, i * 3 + 0);
    p2.fromBufferAttribute(position, i * 3 + 1);
    p3.fromBufferAttribute(position, i * 3 + 2);
    sum += signedVolumeOfTriangle(p1, p2, p3);
  }
  return Math.round(sum);
}

function areaOfTriangle(p1, p2, p3) {
  const v0 = new THREE.Vector3().subVectors(p2, p1);
  const v1 = new THREE.Vector3().subVectors(p3, p1);
  const cross = new THREE.Vector3().crossVectors(v0, v1);
  return cross.length() * 0.5;
}

export async function getModelArea(file) {
  const geometry = await loadGeometryFromFile(file);
  let position = geometry.attributes.position;
  let faces = position.count / 3;
  let sum = 0;
  let p1 = new THREE.Vector3(),
    p2 = new THREE.Vector3(),
    p3 = new THREE.Vector3();
  for (let i = 0; i < faces; i++) {
    p1.fromBufferAttribute(position, i * 3 + 0);
    p2.fromBufferAttribute(position, i * 3 + 1);
    p3.fromBufferAttribute(position, i * 3 + 2);
    sum += areaOfTriangle(p1, p2, p3);
  }
  return Math.round(sum);
}

export async function scaleModel(file, scale) {
  const geometry = await loadGeometryFromFile(file);
  geometry.scale(scale, scale, scale);
  const fileType = file.name.split(".").pop().toLowerCase(); // 파일 확장자 추출
  return updateModelBlobAndDimensions(geometry, fileType);
}

export async function rotateModel(file, axis, rotation) {
  const geometry = await loadGeometryFromFile(file);
  if (axis == "x")
    geometry.rotateX(rotation == "cw" ? Math.PI / -2 : Math.PI / 2);
  if (axis == "y")
    geometry.rotateY(rotation == "cw" ? Math.PI / -2 : Math.PI / 2);
  if (axis == "z")
    geometry.rotateZ(rotation == "cw" ? Math.PI / -2 : Math.PI / 2);
  const fileType = file.name.split(".").pop().toLowerCase(); // 파일 확장자 추출
  return updateModelBlobAndDimensions(geometry, fileType);
}

export function createPreviewImage(file) {
  return new Promise((resolve, reject) => {
    loadGeometryFromFile(file)
      .then((geometry) => {
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xffffff); // 배경색을 흰색으로 설정
        const camera = new THREE.PerspectiveCamera(60, 4 / 3, 0.1, 2000);
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(480, 360);

        // Use try-finally to ensure renderer cleanup
        try {
          const gridHelper = new THREE.GridHelper(300, 30);
          gridHelper.position.set(0, 0, 0);
          scene.add(gridHelper);
          const controls = new OrbitControls(camera, renderer.domElement);

          geometry.rotateX(Math.PI / -2);
          geometry.computeBoundingBox();
          const boundingBox = geometry.boundingBox;
          const centerX = (boundingBox.max.x + boundingBox.min.x) / 2;
          const centerZ = (boundingBox.max.z + boundingBox.min.z) / 2;
          const minY = boundingBox.min.y;
          geometry.translate(-centerX, -minY, -centerZ);

          const material = new THREE.MeshPhongMaterial({ color: 0xaecdff });
          const mesh = new THREE.Mesh(geometry, material);
          scene.add(mesh);

          // 조명 설정
          const lightColor = 0xffffff;
          const topLight = new THREE.DirectionalLight(lightColor, 0.4);
          topLight.position.set(0, 100, 0);
          scene.add(topLight);

          const cameraLight = new THREE.DirectionalLight(lightColor, 0.6);
          scene.add(cameraLight);

          const box = new THREE.Box3().setFromObject(mesh);
          const size = box.getSize(new THREE.Vector3());
          const center = box.getCenter(new THREE.Vector3());
          const xyDistance = Math.max(size.x, size.z) * 0.7;
          const cameraX = center.x + xyDistance; // 우측 45도
          const cameraY = center.y + xyDistance + size.y / 2; // 위에서 45도
          const cameraZ = center.z + xyDistance; // Z 오프셋 추가

          camera.position.set(cameraX, cameraY, cameraZ);
          cameraLight.position.copy(camera.position);
          camera.near = xyDistance / 100;
          camera.far = xyDistance * 10;
          camera.updateProjectionMatrix();
          controls.target.set(0, size.y / 4, 0);
          controls.update();
          renderer.render(scene, camera);

          const previewImageUrl = renderer.domElement.toDataURL("image/png");

          resolve(previewImageUrl); // Promise가 성공적으로 완료되었음을 알림
        } catch (error) {
          reject(error);
        } finally {
          renderer.dispose(); // Clean up the renderer
        }
      })
      .catch(reject); // loadGeometryFromFile이 실패하면 Promise를 reject
  });
}

function updateModelBlobAndDimensions(geometry, fileType) {
  let exporter;
  let blob;

  if (fileType === "stl") {
    exporter = new STLExporter();
    blob = new Blob(
      [
        exporter.parse(
          new THREE.Mesh(geometry, new THREE.MeshPhongMaterial()),
          { binary: true }
        ),
      ],
      {
        type: "application/octet-stream",
      }
    );
  } else if (fileType === "obj") {
    exporter = new OBJExporter(); // OBJExporter 사용
    const result = exporter.parse(
      new THREE.Mesh(geometry, new THREE.MeshPhongMaterial())
    );
    blob = new Blob([result], { type: "text/plain" });
  } else {
    throw new Error("Unsupported export format.");
  }

  const url = URL.createObjectURL(blob);
  return url;
}
