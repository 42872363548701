<template>
  <div
    class="d-flex flex-column pa-3"
    style="
      gap: 12px;
      border-radius: 8px;
      border: 1px solid var(--v-background_dark-base);
    "
  >
    <div class="d-flex justify-space-between">
      <div class="title-2 font-weight-bold onSurfae_dark--text">주문 정보</div>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        x-small
        @click="display = !display"
      >
        <feather :type="display ? 'chevron-up' : 'chevron-down'" />
      </v-btn>
    </div>
    <div v-if="display" class="d-flex flex-column" style="gap: 4px">
      <div
        v-for="item in items"
        :key="item.title"
        class="py-1 px-2 d-flex justify-space-between subtitle-1"
      >
        <div class="d-flex" style="gap: 8px">
          <feather :type="item.icon" size="20" />
          <div
            class="font-weight-bold onSurface_normal--text d-flex align-center"
          >
            {{ item.title }}
          </div>
        </div>
        <editable-display
          :align="'end'"
          :edit="item.edit"
          :value="order[item.value]"
          :valueKey="item.value"
          @update="updateOrder(item.value, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-DataCard",
  props: { order: Object },
  watch: {
    order: {
      deep: true,
      immediate: true,
      handler() {
        this.items = [
          {
            icon: "file-text",
            title: "사용 목적",
            value: "purpose",
            edit: false,
          },
          {
            icon: "activity",
            title: "주문 상태",
            value: "status",
            edit: true,
          },
          {
            icon: "home",
            title: "작업 장소",
            value: "workspace",
            edit: true,
          },
          {
            icon: "user",
            title: "메이커 ID",
            value: "makerId",
            edit: false,
          },
          {
            icon: "clock",
            title: "생성 일시",
            value: "createdAt",
            edit: false,
          },
          {
            icon: "clock",
            title: "수정 일시",
            value: "updatedAt",
            edit: false,
          },
          {
            icon: "credit-card",
            title: "결제 방식",
            value: "paymentMethod",
            edit: true,
          },
          {
            icon: "message-square",
            title: "작업 메모",
            value: "printmemo",
            edit: true,
          },
          {
            icon: "clock",
            title: "결제 일시",
            value: "paidAt",
            edit: false,
          },
          {
            icon: "truck",
            title: "배송 방법",
            value: "shippingMethod",
            edit: true,
          },
          {
            icon: "map",
            title: "배송 정보",
            value: "address",
            edit: true,
          },
          {
            icon: "truck",
            title: "송장 번호",
            value: "trackingCode",
            edit: true,
          },
          {
            icon: "clock",
            title: "희망 출고",
            value: "dueAt",
            edit: true,
          },
          {
            icon: "clock",
            title: "출고 일자",
            value: "shipAt",
            edit: false,
          },
        ];
      },
    },
  },
  data() {
    return {
      display: true,
      items: [],
    };
  },
  methods: {
    updateOrder(field, value) {
      this.$emit("update", { field, value }); // 부모 컴포넌트에 값 전송
    },
  },
};
</script>

<style></style>
