<template>
  <div
    class="d-flex flex-column pa-3"
    style="
      gap: 12px;
      border-radius: 8px;
      border: 1px solid var(--v-background_dark-base);
      width: 100%;
    "
  >
    <div class="d-flex justify-space-between">
      <div class="title-2 font-weight-bold onSurfae_dark--text">3D모델링</div>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        x-small
        @click="display = !display"
      >
        <feather :type="display ? 'chevron-up' : 'chevron-down'" />
      </v-btn>
    </div>
    <div v-if="display" class="d-flex flex-column" style="gap: 12px">
      <div class="d-flex subtitle-1 onSurface_normal--text">
        {{ order.modelingMemo }}
      </div>
      <div class="d-flex flex-column" style="gap: 4px">
        <div class="d-flex subtitle-2 onSurface_normal--text">첨부 도면</div>
        <v-row dense>
          <v-col
            cols="12"
            lg="6"
            xl="4"
            v-for="item in order.modelingFile.filter((v) => !v.delete)"
            :key="item.name"
          >
            <div
              class="d-flex pa-2"
              :style="
                item.selected
                  ? 'border: 1px solid var(--v-primary-base)'
                  : 'border: 1px solid var(--v-background_dark-base)'
              "
              style="border-radius: 4px; gap: 8px; cursor: pointer"
              @click="open(item.url)"
            >
              <div>
                <v-skeleton-loader
                  v-if="!previewImage(item)"
                  style="width: 40px; height: 40px"
                  type="image"
                />
                <v-img
                  v-else
                  style="border-radius: 4px"
                  :src="previewImage(item)"
                  max-width="40"
                  max-height="40"
                />
              </div>
              <div
                class="d-flex flex-column justify-space-around"
                style="
                  gap: 8px;
                  white-space: nowrap;
                  overflow: hidden;
                  width: 100%;
                "
              >
                <div class="subtitle-2 font-weight-bold onSurface_dark--text">
                  {{ item.name }}
                </div>
                <div
                  class="d-flex justify-space-between subtitle-2 font-weight-regular onSurface_dark--text"
                >
                  <div>
                    {{
                      item.size >= 1024 * 1024
                        ? (item.size / 1024 / 1024).toFixed(2) + "MB"
                        : (item.size / 1024).toFixed(0) + "KB"
                    }}
                  </div>
                  <div>
                    {{ item.type.toUpperCase() }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-ModelingCard",
  props: { order: Object },
  data() {
    return {
      display: true,
    };
  },
  methods: {
    previewImage(item) {
      if (item.previewImg) {
        return item.previewImg;
      } else {
        const fileType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        if (["png", "jpg", "jpeg", "gif", "bmp", "webp"].includes(fileType)) {
          return item.url;
        }
        if (fileType == "pdf") {
          return "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FPDF_thumbnail.png?alt=media&token=1cc80cdb-6e88-432d-b7ba-e8a0b6ac8c80";
        }
        return null;
      }
    },
    open(url) {
      window.open(url);
    },
  },
};
</script>

<style></style>
