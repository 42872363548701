<template>
  <div
    class="d-flex flex-column pa-3"
    style="
      gap: 12px;
      border-radius: 8px;
      border: 1px solid var(--v-background_dark-base);
      width: 100%;
    "
  >
    <!-- 상단 타이틀바 -->
    <div class="d-flex justify-space-between">
      <div class="title-2 font-weight-bold onSurfae_dark--text">3D프린팅</div>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        x-small
        @click="display = !display"
      >
        <feather :type="display ? 'chevron-up' : 'chevron-down'" />
      </v-btn>
    </div>
    <!-- 본문 섹션 -->
    <div v-if="display" class="d-flex flex-column" style="gap: 12px">
      <span
        class="py-1 px-2 onSurface_normal--text subtitle-1 flex-wrap"
        style="
          word-wrap: break-word; /* 긴 단어 줄바꿈 */
          word-break: break-word; /* 단어가 너무 길면 강제로 줄바꿈 */
          white-space: pre-wrap; /* 줄바꿈과 공백 유지 */
          max-width: 100%; /* 텍스트의 최대 너비 제한 */
          overflow-wrap: break-word; /* 텍스트 넘침 방지 */
        "
      >
        {{ order.memo }}
      </span>
      <div class="d-flex px-2" style="gap: 16px">
        <!-- 좌측 파일 옵션 수정 패널 -->
        <div
          class="d-flex flex-column"
          style="gap: 16px; width: 50%; height: fit-content"
        >
          <div class="d-flex flex-column" style="gap: 4px">
            <div class="subtitle-1 font-weight-bold onSurface_dark--text">
              {{
                selectedFile.length === 0
                  ? "선택된 파일이 없습니다."
                  : selectedFile.length === 1
                  ? selectedFile[0].name
                  : `${selectedFile[0].name} 외 ${
                      selectedFile.length - 1
                    }개 파일`
              }}
            </div>
            <div
              class="subtitle-2 font-weight-regular error--text"
              v-if="isDuplicateSelected"
            >
              한 파일에 여러 옵션이 존재하는 경우 일괄 수정이 불가합니다.
            </div>
          </div>
          <div class="d-flex" style="gap: 8px" v-if="!isDuplicateSelected">
            <file-viewer-card
              style="max-width: 480px"
              :file="selectedFile.length === 1 ? selectedFile[0] : {}"
            />
            <div
              class="d-flex flex-column subtitle-2 onSurface_normal--text"
              style="min-width: 100px; gap: 4px"
            >
              <div>{{ `X: ${selectedFile[0]?.dimensions?.x}mm` }}</div>
              <div>{{ `Y: ${selectedFile[0]?.dimensions?.y}mm` }}</div>
              <div>{{ `Z: ${selectedFile[0]?.dimensions?.z}mm` }}</div>
              <div>
                {{ `V: ${selectedFile[0]?.dimensions?.v?.toLocaleString()}` }}
              </div>
              <div>
                {{ `A: ${selectedFile[0]?.dimensions?.a?.toLocaleString()}` }}
              </div>
              <div>
                {{
                  `100%: ${(
                    (Math.round(selectedFile[0]?.dimensions?.v * 0.00124) + 4) *
                    60
                  ).toLocaleString()}원`
                }}
              </div>
              <div>
                {{
                  `20%: ${(
                    Math.round(
                      ((selectedFile[0]?.dimensions?.v -
                        selectedFile[0]?.dimensions?.a) *
                        0.2 +
                        selectedFile[0]?.dimensions?.a) *
                        0.00124
                    ) * 60
                  ).toLocaleString()}원`
                }}
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column"
            style="gap: 4px"
            v-if="!isDuplicateSelected"
          >
            <div
              class="d-flex flex-column"
              style="gap: 12px"
              v-if="selectedFile.length === 1"
            >
              <div class="subtitle-2 font-weight-bold">파일 메모</div>
              <div
                class="d-flex flex-wrap subtitle-2 onSurface_dark--text"
                style="gap: 12px"
              >
                <div
                  class="div-border align-center px-3"
                  style="height: 30px; width: 150px"
                >
                  <editable-display
                    :align="'start'"
                    style="width: 100%"
                    :edit="true"
                    :value="selectedFile[0].memo"
                    :valueKey="'text'"
                    @update="
                      ($event) => {
                        if (
                          !Object.prototype.hasOwnProperty.call(
                            selectedFile[0],
                            'memo'
                          )
                        ) {
                          this.$set(selectedFile[0], 'memo', '');
                        }
                        selectedFile[0].memo = $event;
                      }
                    "
                  />
                </div>
              </div>
              <div class="subtitle-2 font-weight-bold">옵션 별 수량</div>
              <div class="d-flex flex-column" style="gap: 4px">
                <div
                  class="py-2 justify-space-between div-border px-3 subtitle-2 align-center"
                  style="width: 100%; cursor: pointer"
                  :style="
                    option.selected
                      ? 'border: 1px solid var(--v-primary-base);'
                      : null
                  "
                  v-for="(option, i) in selectedFile[0].option"
                  :key="i"
                  @click="
                    selectedFile[0].option = selectedFile[0].option.slice();
                    selectedFile[0].option.forEach((opt, index) => {
                      opt.selected = index === i;
                    });
                  "
                >
                  <div>
                    {{
                      `${option?.method} / ${option?.material} / ${
                        option?.color
                      } (${option?.layer}mm / ${option?.wall}겹 / ${
                        option?.infill
                      }) - ${option?.price.toLocaleString()}원`
                    }}
                  </div>
                  <div
                    class="d-flex subtitle-2 align-center"
                    :style="
                      $vuetify.breakpoint.smAndDown ? 'gap: 12px' : 'gap: 16px'
                    "
                  >
                    <div>{{ option?.totalQty + "EA" }}</div>
                    <feather
                      size="22"
                      type="trash"
                      stroke="var(--v-error-base)"
                      style="vertical-align: middle"
                      @click="deleteOption(i)"
                    />
                  </div>
                </div>
                <div
                  class="d-flex justify-center align-center py-2 subtitle-2 font-weight-bold"
                  style="
                    border-radius: 4px;
                    border: 3px dashed var(--v-primary-base);
                  "
                  @click="addOption()"
                >
                  + 새 옵션 추가하기
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column"
            style="gap: 8px"
            v-if="selectedFile.length >= 1 && !isDuplicateSelected"
          >
            <div class="subtitle-2 font-weight-bold">옵션 수정</div>
            <div class="d-flex flex-wrap" style="gap: 12px">
              <v-menu rounded="lg" bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="px-3 py-2 div-border"
                    style="height: 30px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="subtitle-2 font-weight-regular onSurface_dark--text"
                    >
                      {{ selectedOption.method }}
                    </span>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in Object.keys(printOption)"
                    :key="index"
                    @click="
                      () => {
                        selectedOptions.forEach((o) => {
                          o.method = item;
                          o.material = Object.keys(
                            printOption[item].materials
                          )[0];
                          o.color = Object.keys(
                            printOption[item].materials[o.material].colors
                          )[0];
                        });
                      }
                    "
                  >
                    <v-list-item-title>
                      <span
                        class="subtitle-2 font-weight-regular onSurface_dark--text"
                        v-text="item"
                      />
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu rounded="lg" bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="px-3 py-2 div-border"
                    style="height: 30px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="subtitle-2 font-weight-regular onSurface_dark--text"
                    >
                      {{ selectedOption.material }}
                    </span>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in printOption[selectedOption.method]
                      .materials"
                    :key="index"
                    @click="
                      selectedOptions.forEach((o) => {
                        o.material = index;
                        o.color = Object.keys(
                          printOption[o.method].materials[index].colors
                        )[0];
                      })
                    "
                  >
                    <v-list-item-title>
                      <span
                        class="subtitle-2 font-weight-regular onSurface_dark--text"
                        v-text="index"
                      />
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu rounded="lg" bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="px-3 py-2 div-border"
                    style="height: 30px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="subtitle-2 font-weight-regular onSurface_dark--text"
                    >
                      {{ selectedOption.color }}
                    </span>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in printOption[selectedOption.method]
                      .materials[selectedOption.material].colors"
                    :key="index"
                    @click="
                      selectedOptions.forEach((o) => {
                        o.color = index;
                      })
                    "
                  >
                    <v-list-item-title>
                      <span
                        class="subtitle-2 font-weight-regular onSurface_dark--text"
                        v-text="index"
                      />
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                rounded="lg"
                bottom
                offset-y
                v-if="selectedOption.method === 'FDM'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="px-3 py-2 div-border"
                    style="height: 30px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="subtitle-2 font-weight-regular onSurface_dark--text"
                    >
                      {{ selectedOption.layer + "mm" }}
                    </span>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(value, item) in printOption[selectedOption.method]
                      .materials[selectedOption.material].layer"
                    :key="item"
                    @click="
                      selectedOptions.forEach((o) => {
                        o.layer = value;
                      })
                    "
                  >
                    <v-list-item-title>
                      <span
                        class="subtitle-2 font-weight-regular onSurface_dark--text"
                        v-text="value + 'mm'"
                      />
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                rounded="lg"
                bottom
                offset-y
                v-if="selectedOption.method === 'FDM'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="px-3 py-2 div-border"
                    style="height: 30px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="subtitle-2 font-weight-regular onSurface_dark--text"
                    >
                      {{ selectedOption.wall + "겹" }}
                    </span>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(value, item) in printOption[selectedOption.method]
                      .materials[selectedOption.material].wall"
                    :key="item"
                    @click="
                      selectedOptions.forEach((o) => {
                        o.wall = value;
                      })
                    "
                  >
                    <v-list-item-title>
                      <span
                        class="subtitle-2 font-weight-regular onSurface_dark--text"
                        v-text="value + '겹'"
                      />
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                rounded="lg"
                bottom
                offset-y
                v-if="selectedOption.method === 'FDM'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="px-3 py-2 div-border"
                    style="height: 30px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="subtitle-2 font-weight-regular onSurface_dark--text"
                    >
                      {{ selectedOption.infill }}
                    </span>
                  </div>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(value, item) in printOption[selectedOption.method]
                      .materials[selectedOption.material].infill"
                    :key="item"
                    @click="
                      selectedOptions.forEach((o) => {
                        o.infill = value;
                      })
                    "
                  >
                    <v-list-item-title>
                      <span
                        class="subtitle-2 font-weight-regular onSurface_dark--text"
                        v-text="value"
                      />
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div
            class="d-flex flex-column"
            style="gap: 8px"
            v-if="selectedFile.length >= 1 && !isDuplicateSelected"
          >
            <div class="subtitle-2 font-weight-bold">견적 입력</div>
            <div
              class="d-flex flex-wrap subtitle-2 onSurface_dark--text"
              style="gap: 12px"
            >
              <div
                class="div-border align-center px-3 subtitle-2"
                style="height: 30px; width: 80px"
              >
                <editable-display
                  style="width: 100%"
                  :edit="true"
                  :value="selectedOption.price"
                  :valueKey="'number'"
                  @update="
                    selectedOptions.forEach((o) => {
                      o.price = $event;
                    })
                  "
                />
              </div>
              <div class="d-flex align-center">X</div>
              <div
                class="div-border align-center px-3"
                style="height: 30px; width: 60px"
              >
                <editable-display
                  style="width: 100%"
                  :edit="true"
                  :value="selectedOption.totalQty"
                  :valueKey="'number'"
                  @update="
                    selectedOptions.forEach((o) => {
                      o.totalQty = $event;
                    })
                  "
                />
              </div>
              <div class="d-flex align-center">=</div>
              <div class="d-flex align-center">
                {{
                  (
                    selectedOption.price * selectedOption.totalQty
                  ).toLocaleString()
                }}
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column"
            style="gap: 8px"
            v-if="selectedFile.length >= 1 && !isDuplicateSelected"
          >
            <div class="subtitle-2 font-weight-bold">출력 진행 상황</div>
            <div
              class="d-flex flex-wrap subtitle-2 onSurface_dark--text"
              style="gap: 12px"
            >
              <div
                class="div-border align-center px-3"
                style="height: 30px; width: 60px"
              >
                <editable-display
                  style="width: 100%"
                  :edit="true"
                  :value="selectedOption.finishQty"
                  :valueKey="'number'"
                  @update="
                    selectedOptions.forEach((o) => {
                      o.finishQty = $event;
                    })
                  "
                />
              </div>
              <div class="d-flex align-center">/</div>
              <div class="d-flex align-center" style="height: 30px">
                {{ selectedOption.totalQty }}
              </div>
            </div>
          </div>
        </div>
        <!-- 우측 파일 리스트 패널 -->
        <div class="d-flex flex-column" style="gap: 16px; width: 50%">
          <div
            class="d-flex subtitle-2 onSurface_dark--text justify-space-between align-center"
          >
            <div
              style="cursor: pointer"
              @click="
                const allSelected = order.file?.every((item) => item.selected);
                order.file.forEach((item) => (item.selected = !allSelected));
                if (allSelected) order.file[0].selected = true;
                order.file = order.file?.slice();
              "
            >
              {{
                order.file?.length != 0
                  ? order.file?.every((item) => item.selected)
                    ? "파일 선택 해제"
                    : "파일 선택"
                  : "파일 선택"
              }}
            </div>
            <v-btn
              icon
              x-small
              @click="
                const allSelected = order.file?.every((item) => item.print);
                order.file.forEach((item) => (item.print = !allSelected));
                order.file = order.file?.slice();
              "
            >
              <feather
                :type="
                  order.file?.every((item) => item.print)
                    ? 'check-square'
                    : 'square'
                "
                :stroke="
                  order.file?.every((item) => item.print)
                    ? 'var(--v-primary-base)'
                    : 'var(--v-onSurface_normal-base)'
                "
                size="16px"
              />
            </v-btn>
          </div>
          <div
            class="d-flex flex-column"
            style="overflow: auto; max-height: 720px; gap: 4px"
            :style="{
              backgroundColor: dragover ? 'rgba(0, 0, 0, 0.05)' : '',
            }"
            @drop.prevent="onFileDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
          >
            <div
              v-for="item in order.file"
              :key="item.name"
              class="pa-2 div-border flex-column"
              style="cursor: pointer; gap: 8px"
              :style="
                item.selected && 'border: 1px solid var(--v-primary-base)'
              "
              @click="onFileClick(item, $event)"
            >
              <div class="d-flex" style="gap: 12px">
                <div>
                  <v-skeleton-loader
                    v-if="!previewImage(item)"
                    style="width: 48px; height: 36px"
                    type="image"
                  />
                  <v-img
                    v-else
                    style="border-radius: 4px"
                    :src="previewImage(item)"
                    max-width="48"
                    max-height="36"
                  />
                </div>
                <div class="d-flex flex-column" style="width: 100%; gap: 4px">
                  <v-hover v-slot="{ hover }" close-delay="100">
                    <div
                      class="subtitle-2 font-weight-bold mr-auto"
                      :class="hover ? 'primary--text' : 'onSurface_dark--text'"
                      style="cursor: pointer"
                      @click="open(item.url)"
                    >
                      {{ item.name }}
                    </div>
                  </v-hover>
                  <div
                    class="caption font-weight-regular onSurface_light--text"
                  >
                    {{
                      `${item.dimensions?.x} * ${item.dimensions?.y} * ${item.dimensions?.z}`
                    }}
                    {{ item.memo && ` - ${item.memo}` }}
                  </div>
                </div>
                <div class="align-center">
                  <v-btn icon x-small @click="item.print = !item.print">
                    <feather
                      :type="item.print ? 'check-square' : 'square'"
                      :stroke="
                        item.print
                          ? 'var(--v-primary-base)'
                          : 'var(--v-onSurface_normal-base)'
                      "
                      size="16px"
                    />
                  </v-btn>
                </div>
              </div>
              <div class="px-2 d-flex flex-column" style="gap: 4px">
                <div
                  v-for="(option, i) in item.option"
                  :key="i"
                  class="d-flex flex-column onSurface_normal--text subtitle-2"
                >
                  <div class="d-flex justify-space-between">
                    <div>
                      {{
                        `${option?.method} / ${option?.material} / ${option?.color} (${option?.layer} / ${option?.wall} / ${option?.infill})`
                      }}
                    </div>
                    <div>{{ `개당 ${option?.price.toLocaleString()}원` }}</div>
                  </div>
                  <div class="d-flex justify-space-between pl-2">
                    <div>
                      {{
                        `(완료 ${option?.finishQty}개) 총 ${option?.totalQty}개`
                      }}
                    </div>
                    <div>
                      {{
                        `총 ${(
                          option?.price * option?.totalQty
                        ).toLocaleString()}원`
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column" style="gap: 4px">
              <div
                class="px-3 py-2 d-flex flex-column justify-center"
                style="
                  cursor: pointer;
                  width: 100%;
                  border: 3px dashed var(--v-primary-base);
                  gap: 4px;
                  border-radius: 4px;
                "
                @click="$refs.uploader.click()"
              >
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  @change="onFileInput"
                  multiple
                />
                <div
                  class="subtitle-2 font-weight-bold onSurface_dark--text d-flex justify-center"
                >
                  + 새 파일 업로드
                </div>
                <div
                  class="subtitle-2 font-weight-regular onSurface_normal--text d-flex justify-center text-center"
                >
                  클릭 또는 드래그로 파일 업로드
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 하단 버튼 섹션 -->
    <div class="py-2 justify-end d-flex" style="gap: 12px">
      <v-btn
        class="subtitle-1 font-weight-bold py-2 px-4"
        elevation="0"
        height="32px"
        :loading="HoldBtnLoading"
        @click="HoldBtn()"
      >
        보완 요청
      </v-btn>
      <v-btn
        class="subtitle-1 font-weight-bold px-4"
        elevation="0"
        color="primary"
        height="32px"
        :loading="QuoteBtnLoading"
        @click="QuoteBtn()"
      >
        견적 안내
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  getModelDimensions,
  getModelVolume,
  getModelArea,
  createPreviewImage,
} from "@/API/modelUtils.js";
import FileViewerCard from "@/components/Preview/FileViewerCard.vue";
import constants from "@/utils/constants.js";

export default {
  name: "Admin-Order-PrintingCard",
  components: { FileViewerCard },

  props: {
    order: {
      type: Object,
      default: constants.newOrderFormInterface,
    },
  },
  data() {
    return {
      display: true,
      dragover: false,

      isDuplicateSelected: false,
      selectedOptions: [],
      selectedOption: {},
      printOption: constants.newPrintOption,

      HoldBtnLoading: false,
      QuoteBtnLoading: false,
    };
  },
  watch: {
    order: {
      deep: true,
      immediate: true,
      handler(n, o) {
        this.order.printingPrice = this.order.file?.reduce((total, f) => {
          if (f.print) {
            return (
              total +
              f.option.reduce((optTotal, o) => {
                return optTotal + o.price * o.totalQty;
              }, 0)
            );
          }
          return total;
        }, 0);
        if (n?.id === o?.id) return;

        this.display = true;
      },
    },

    selectedFile: {
      immediate: true,
      deep: true,
      handler(newSelectedFiles) {
        // selectedOptions 계산 및 업데이트
        this.selectedOptions = newSelectedFiles.map((file) =>
          file.option?.find((v) => v.selected)
        );
        this.selectedOption = this.selectedOptions[0];

        // isDuplicateSelected 계산 및 업데이트
        this.isDuplicateSelected =
          newSelectedFiles.length > 1 &&
          newSelectedFiles.some((file) => file.option.length > 1);
      },
    },
  },
  computed: {
    selectedFile() {
      return this.order.file?.filter((file) => file.selected) || [];
    },
  },
  methods: {
    deleteOption(index) {
      const fileOptions = this.selectedFile[0].option;
      if (fileOptions.length > 1) {
        fileOptions.splice(index, 1);
      } else {
        this.$toasted.global.error("옵션은 최소 1개가 있어야 합니다.");
      }
    },
    addOption() {
      const selected = this.selectedFile[0];
      if (!selected) {
        return this.$toasted.global.error(
          "옵션을 추가할 파일이 선택되지 않았습니다."
        );
      }
      const currentSelectedOption = selected.option.find((v) => v.selected);
      selected.option.forEach((v) => (v.selected = false));
      const newOption = {
        ...currentSelectedOption,
        selected: true,
        method: currentSelectedOption?.method || "FDM",
        material: currentSelectedOption?.material || "PLA",
        color: currentSelectedOption?.color || "White",
        layer: currentSelectedOption?.layer || "0.2",
        wall: currentSelectedOption?.wall || "2",
        infill: currentSelectedOption?.infill || "20%",
        totalQty: currentSelectedOption?.totalQty || 1,
        finishQty: 0,
        price: 0,
      };
      selected.option.unshift(newOption);
    },
    open(url) {
      window.open(url);
    },
    onFileDrop(e) {
      this.dragover = false;
      e.dataTransfer.files.forEach((v) => this.addFile(v));
      this.$refs.uploader.value = "";
    },
    onFileInput(event) {
      event.target.files.forEach((v) => this.addFile(v));
      this.$refs.uploader.value = "";
    },
    addFile(v) {
      const fileType = v.name.slice(v.name.lastIndexOf(".") + 1).toLowerCase();
      if (
        ["3dm", "skp", "f3d", "igs", "gcode", "sldprt", "ply", "hfb"].includes(
          fileType
        )
      ) {
        this.$toasted.global.error(
          fileType + " 파일은 확인이 불가합니다. STL 파일로 올려주세요!"
        );
        return;
      }
      if (v.size >= 50000000) {
        this.$toasted.global.error(`50MB 이하 파일만 등록 가능합니다.`);
        return;
      }
      const fileNameExists = this.order.file.some(
        (file) => file.name === v.name
      );
      if (fileNameExists) {
        this.$toasted.global.error(`이미 같은 이름의 파일이 존재합니다.`);
        return;
      }
      const file = {
        selected: true,
        id: "",
        name: v.name,
        size: v.size,
        type: fileType,
        url: URL.createObjectURL(v),
        dimensions: { x: 0, y: 0, z: 0, v: 0, a: 0 },
        previewImg: "",
        print: false,
        option: [
          {
            selected: true,
            method: "FDM", // printMethod
            material: "PLA", // printMaterial
            color: "White", // printColor
            layer: "0.2", // layer Height
            wall: "2", // wall Count
            infill: "20%", // infill Density
            totalQty: 1, // print Quantity
            finishQty: 0, // 완료된 수량
            price: 0, // 옵션 가격
          },
        ],
      };
      this.order.file = this.order.file.map((file) => ({
        ...file,
        selected: false,
      }));
      this.order.file.unshift(file);
      if (["stl", "obj", "3mf"].includes(fileType)) {
        this.updateModelDetails(file);
      }
    },
    onFileClick(item, event) {
      if (event.ctrlKey) {
        // Vue.set을 사용하여 배열 속성 변경 감지
        this.$set(item, "selected", !item.selected);
      } else {
        this.order.file.forEach((i) => {
          this.$set(i, "selected", false); // Vue.set 사용
        });
        this.$set(item, "selected", true); // Vue.set 사용
      }
      // 배열을 강제로 갱신시키기 위해 slice() 사용
      this.order.file = this.order.file?.slice();
    },
    previewImage(item) {
      if (item.previewImg) {
        return item.previewImg;
      } else {
        const fileType = item?.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        if (["png", "jpg", "jpeg", "gif", "bmp", "webp"].includes(fileType)) {
          return item.url;
        }
        if (fileType == "pdf") {
          return "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FPDF_thumbnail.png?alt=media&token=1cc80cdb-6e88-432d-b7ba-e8a0b6ac8c80";
        }
        return null;
      }
    },
    async updateModelDetails(file) {
      const dimensions = await getModelDimensions(file);
      const volume = await getModelVolume(file);
      const area = await getModelArea(file);
      const previewImg = await createPreviewImage(file);
      const fileToUpdate = this.order.file.find((f) => f.name === file.name);
      if (fileToUpdate) {
        fileToUpdate.previewImg = previewImg;
        fileToUpdate.dimensions = dimensions;
        fileToUpdate.dimensions.v = volume;
        fileToUpdate.dimensions.a = area;
      }
    },
    async HoldBtn() {
      if (this.order.status == "Pending") {
        return this.$toasted.global.error("상담 시작을 먼저 진행해 주세요.");
      }
      this.HoldBtnLoading = true;
      try {
        const response = await this.$axios.post("notice/orderHold", {
          order: this.order,
        });
        // 메시지 발송이 성공했을 때만 안내 문구를 표시
        if (response.status === 200) {
          this.$toasted.global.notice(
            "고객에게 보완 요청 메세지가 전송되었습니다."
          );
          this.$emit("update", { field: "status", value: "Hold" }); // 부모 컴포넌트에 값 전송
          this.$emit("saveOrder");
        } else {
          this.$toasted.global.error(
            "메세지 전송에 실패했습니다. 다시 시도해주세요."
          );
        }
      } catch (error) {
        console.error("메시지 발송 중 오류:", error);
        this.$toasted.global.error(
          "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
        );
      }
      this.HoldBtnLoading = false;
    },
    async QuoteBtn() {
      if (!this.order.file.some((v) => v.print)) {
        return this.$toasted.global.error("선택된 파일이 없습니다");
      }
      if (this.order.status == "Pending") {
        return this.$toasted.global.error("상담 시작을 먼저 진행해 주세요.");
      }
      this.QuoteBtnLoading = true;
      try {
        const response = await this.$axios.post("notice/orderPrintQuote", {
          order: this.order,
        });
        // 메시지 발송이 성공했을 때만 안내 문구를 표시
        if (response.status === 200) {
          this.$toasted.global.notice(
            "고객에게 결제 안내 메세지가 전송되었습니다."
          );
        } else {
          this.$toasted.global.error(
            "메세지 전송에 실패했습니다. 다시 시도해주세요."
          );
        }
      } catch (error) {
        console.error("메시지 발송 중 오류:", error);
        this.$toasted.global.error(
          "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
        );
      }
      this.QuoteBtnLoading = false;
    },
    updateOrder() {
      this.$emit("update", { field: "file", value: this.order.file }); // 부모 컴포넌트에 값 전송
    },
  },
};
</script>

<style>
.div-border {
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--v-background_dark-base);
}
</style>
