import { render, staticRenderFns } from "./FileViewerCard.vue?vue&type=template&id=aa2e062a&scoped=true"
import script from "./FileViewerCard.vue?vue&type=script&lang=js"
export * from "./FileViewerCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa2e062a",
  null
  
)

export default component.exports