<template>
  <div class="d-flex flex-column" style="width: 100%">
    <div style="min-height: 2px" v-if="loading">
      <v-progress-linear indeterminate />
    </div>
    <div class="d-flex" style="width: 100%">
      <filter-sidebar-vue
        :orders="orders"
        :filter.sync="filter"
        :search.sync="search"
        v-if="!search"
        @changeFilter="changeFilter"
      />
      <div class="d-flex flex-column" style="width: 100%">
        <search-bar-vue
          v-if="search"
          :search.sync="search"
          :searchedData.sync="searchedData"
          :searchedList.sync="searchedList"
          :order="selectedOrder"
          :loading.sync="loading"
        />
        <div class="d-flex" style="height: 100vh; min-width: min-content">
          <list-sidebar-vue
            :orders="search ? searchedList : orderList"
            :selectedOrder.sync="selectedOrder"
            :filter="filter"
            :search="search"
          />
          <data-section-vue :order="selectedOrder" @deleteOrder="deleteOrder" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchBarVue from "./searchBar.vue";
import DataSectionVue from "./DataSection.vue";
import FilterSidebarVue from "./FilterSidebar.vue";
import ListSidebarVue from "./ListSidebar.vue";

import ORDER from "@/API/order.js";

export default {
  name: "Admin-Order",
  components: {
    searchBarVue,
    DataSectionVue,
    FilterSidebarVue,
    ListSidebarVue,
  },
  data() {
    return {
      search: false,
      searchedData: "",
      filter: { status: "Pending", workspace: "전체 작업장" },
      orders: [], // 전체 불러온 주문들
      orderList: [], // 각 필터 적용된 리스트(리스트바에 띄우는 것)
      searchedList: [], // 검색 된 리스트
      selectedOrder: {}, // 선택 된 주문서
      unsubscribe: null,
      loading: false,
    };
  },
  mounted() {
    this.getOrders();
    // 키보드 이벤트 리스너 등록
    window.addEventListener("keydown", this.toggleSearch);
  },
  beforeDestroy() {
    // 컴포넌트가 파괴될 때 이벤트 리스너 해제
    window.removeEventListener("keydown", this.toggleSearch);
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.loadQueryOrder();
      },
    },
  },
  methods: {
    toggleSearch(event) {
      // Ctrl+F 조합을 감지
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault(); // 기본 Ctrl+F 동작 방지 (브라우저 검색창 열림 방지)
        this.search = !this.search; // search 상태 토글
      }
    },
    getOrders() {
      this.loading = true;
      this.orders = [];
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      const orderDB = this.$firebase.firestore().collection("orders");
      this.unsubscribe = orderDB
        .where("status", "in", [
          "Draft",
          "Pending",
          "Checking",
          "Hold",
          "Ready",
          "Progress",
          "Finished",
          "Shipped",
        ]) // 활성 상태들 나열
        .orderBy("createdAt", "desc")
        .limit(200)
        .onSnapshot((v) => {
          v.docChanges().forEach((change) => {
            const item = change.doc.data();
            // 날짜 필드 변환
            ["createdAt", "updatedAt", "paidAt", "dueAt", "shipAt"].forEach(
              (key) => {
                if (item[key]?.toDate) {
                  item[key] = item[key].toDate();
                } else if (!item[key]) {
                  item[key] = null; // null로 명시적으로 설정
                }
              }
            );
            if (change.type === "added") {
              this.orders.push(item);
            }
            if (change.type === "modified") {
              const index = this.orders.findIndex(
                (order) => order.id === item.id
              );
              if (index !== -1) {
                this.$set(this.orders, index, item);
              }
            }
            if (change.type === "removed") {
              const index = this.orders.findIndex(
                (order) => order.id === item.id
              );
              if (index !== -1) {
                this.orders.splice(index, 1);
              }
            }
          });
          if (this.$route.query.id) this.loadQueryOrder();
          else {
            this.updateList();
            if (this.orderList.length >= 1) {
              this.$router.push({
                path: this.$route.path,
                query: { id: this.orderList[0].id },
              });
            } else {
              this.changeFilter({
                status: "Checking",
                workspace: "전체 작업장",
              });
            }
          }
          this.loading = false;
        });
    },
    loadQueryOrder() {
      if (this.$route.query.id) {
        if (this.orders.some((order) => order.id === this.$route.query.id)) {
          this.selectedOrder = this.orders.find(
            (order) => order.id === this.$route.query.id
          );
          if (this.selectedOrder.status == "Completed" && !this.search) {
            this.filter = {
              status: "Shipped",
              workspace: this.filter.workspace,
            };
            this.changeFilter(this.filter);
          } else {
            this.filter = {
              status: this.selectedOrder.status,
              workspace: this.filter.workspace,
            };
            this.updateList();
          }
        } else {
          if (
            this.searchedList.some((order) => order.id === this.$route.query.id)
          ) {
            this.selectedOrder = this.searchedList.find(
              (order) => order.id === this.$route.query.id
            );
          } else {
            this.search = true;
          }
        }
      } else {
        this.getOrders();
        this.$router.push({
          path: this.$route.path,
          query: { id: this.orderList[0].id },
        });
      }
    },
    updateList() {
      const filteredOrders =
        this.filter.workspace === "전체 작업장"
          ? this.orders.filter((v) => v.status === this.filter.status)
          : this.orders.filter(
              (v) =>
                v.status === this.filter.status &&
                v.workspace === this.filter.workspace
            );

      // updatedAt 기준으로 내림차순 정렬
      this.orderList = filteredOrders.sort((a, b) => {
        const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
        const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
        return dateB - dateA; // 내림차순 정렬
      });
    },
    changeFilter(filter) {
      this.filter = filter;
      this.updateList();
      this.$router.push({
        path: this.$route.path,
        query: { id: this.orderList[0].id },
      });
    },
    async deleteOrder(order) {
      this.btnLoading = true;
      try {
        // Firestore에서 주문서 삭제
        await ORDER.deleteOrder(order);

        // orderList와 검색 상태 초기화
        this.search = false;
        this.updateList();

        // 다음 주문서 선택
        const nextOrder = this.orderList[0]; // 첫 번째 주문서 선택
        if (nextOrder) {
          this.$router.replace({
            path: this.$route.path,
            query: { ...this.$route.query, id: nextOrder.id },
          });
        } else {
          this.changeFilter({
            status: "Ready",
            workspace: "전체 작업장",
          });
        }
      } catch (error) {
        console.error("Error deleting order:", error);
        this.$toasted.global.error("주문서 삭제 중 오류가 발생했습니다.");
      } finally {
        this.btnLoading = false;
      }
    },
  },
};
</script>

<style></style>
