<template>
  <div
    class="d-flex flex-column pa-3"
    style="
      gap: 12px;
      border-radius: 8px;
      border: 1px solid var(--v-background_dark-base);
      width: 100%;
    "
  >
    <div class="d-flex justify-space-between">
      <div class="title-2 font-weight-bold onSurfae_dark--text">
        프린팅 결과
      </div>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        x-small
        @click="display = !display"
      >
        <feather :type="display ? 'chevron-up' : 'chevron-down'" />
      </v-btn>
    </div>
    <div v-if="display" class="d-flex flex-column" style="gap: 4px"></div>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-ResultCard",
  props: { order: Object },
  data() {
    return {
      display: true,
    };
  },
  methods: {},
};
</script>

<style></style>
