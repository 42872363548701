<template>
  <div
    class="d-flex flex-column pa-3"
    style="
      gap: 12px;
      border-radius: 8px;
      border: 1px solid var(--v-background_dark-base);
      width: 100%;
    "
  >
    <div class="d-flex justify-space-between">
      <div class="title-2 font-weight-bold onSurfae_dark--text">결제 정보</div>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        icon
        x-small
        @click="display = !display"
      >
        <feather :type="display ? 'chevron-up' : 'chevron-down'" />
      </v-btn>
    </div>
    <div v-if="display" class="d-flex flex-column" style="gap: 4px">
      <div
        v-for="item in items"
        :key="item.title"
        class="py-1 px-2 d-flex justify-space-between subtitle-1"
      >
        <div class="d-flex" style="gap: 8px">
          <feather :type="item.icon" size="20" />
          <div
            class="font-weight-bold onSurface_normal--text d-flex align-center"
          >
            {{ item.title }}
          </div>
        </div>
        <editable-display
          :align="'end'"
          :edit="item.edit"
          :value="order[item.value]"
          :valueKey="item.value"
          @update="updateOrder(item.value, $event)"
        />
      </div>
    </div>
    <v-spacer />
    <div class="d-flex justify-end" style="gap: 8px">
      <v-btn
        height="32px"
        class="subtitle-1 font-weight-bold onSurface_dark--text"
        elevation="0"
        @click="
          docType = 'quote';
          dialog = true;
        "
      >
        견적서
      </v-btn>
      <v-btn
        height="32px"
        class="subtitle-1 font-weight-bold onSurface_dark--text"
        elevation="0"
        @click="
          docType = 'receipt';
          dialog = true;
        "
      >
        거래명세표
      </v-btn>
    </div>
    <document-dialog :dialog.sync="dialog" :type="docType" :data="order" />
  </div>
</template>

<script>
import DocumentDialog from "./DocumentDialog.vue";
export default {
  name: "Admin-Order-CustomerCard",
  components: { DocumentDialog },
  props: { order: Object },
  watch: {
    order: {
      deep: true,
      immediate: true,
      handler() {
        this.items = [];
        if (this.order.modelingFile?.length > 0)
          this.items.push({
            icon: "file-text",
            title: "3D모델링",
            value: "modelingPrice",
            edit: true,
          });
        this.items.push(
          {
            icon: "file-text",
            title: "3D프린팅",
            value: "printingPrice",
            edit: false,
          },
          {
            icon: "truck",
            title: "배송비",
            value: "shippingPrice",
            edit: true,
          },
          {
            icon: "percent",
            title: "금액 조정",
            value: "discountPrice",
            edit: true,
          },
          {
            icon: "file-text",
            title: "총 결제액",
            value: "totalPrice",
            edit: false,
          }
        );
      },
    },
  },
  data() {
    return {
      dialog: false,
      docType: "",
      display: true,
      items: [],
    };
  },
  methods: {
    updateOrder(field, value) {
      this.$emit("update", { field, value }); // 부모 컴포넌트에 값 전송
    },
  },
};
</script>

<style></style>
