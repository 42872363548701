var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"min-width":"260px","max-width":"260px","gap":"12px","border-right":"1px solid var(--v-background_dark-base)","border-bottom":"1px solid var(--v-background_dark-base)","height":"100vh"}},[(!_vm.search)?_c('div',{staticClass:"d-flex px-3 py-4 justify-space-between",staticStyle:{"border-bottom":"1px solid var(--v-background_dark-base)"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"8px"}},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.toggleSelectAll()}}},[_c('feather',{attrs:{"type":_vm.areAllSelected ? 'check-square' : 'square',"stroke":_vm.areAllSelected
              ? 'var(--v-primary-base)'
              : 'var(--v-onSurface_dark-base)'}})],1),_c('div',{staticClass:"title-2 font-weight-bold onSurface_dark--text d-flex"},[_vm._v(" 전체 선택 ")])],1),_c('v-btn',{staticClass:"subtitle-2 font-weight-bold onSurface_normal--text px-2",attrs:{"max-height":"30px","min-width":"54px","elevation":"0","loading":_vm.btnLoading},on:{"click":function($event){return _vm.action()}}},[_vm._v(" "+_vm._s(_vm.orders[0]?.status === "Pending" ? "상담 시작" : _vm.orders[0]?.status === "Checking" ? "결제 확인" : _vm.orders[0]?.status === "Ready" ? "작업 시작" : _vm.orders[0]?.status === "Progress" ? "작업 완료" : _vm.orders[0]?.status === "Finished" ? "출고 처리" : _vm.orders[0]?.status === "Shipped" ? "상담 완료" : "메세지 전송")+" ")])],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column px-2 pb-3",class:_vm.search && 'pt-3',staticStyle:{"height":"100%","overflow":"auto"}},_vm._l((_vm.orders),function(order,index){return _c('div',{key:order.id,staticClass:"d-flex pa-2 align-center",staticStyle:{"border-radius":"4px","gap":"8px"},style:(_vm.selectedOrder.id == order.id &&
        'background: var(--v-background_normal-base)'),on:{"click":() => {
          // 현재 경로와 쿼리의 id 값이 동일하지 않을 때만 작동
          if (_vm.$route.query.id !== order.id) {
            _vm.$router.push({
              path: _vm.$route.path,
              query: { id: order.id },
            });
          }
        }}},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.toggleSelect(order, index)}}},[_c('feather',{attrs:{"type":order.selected ? 'check-square' : 'square',"size":"18","stroke":order.selected
              ? 'var(--v-primary-base)'
              : 'var(--v-onSurface_dark-base)'}})],1),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"4px","width":"100%"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex subtitle-2 font-weight-bold onSurface_dark--text"},[_vm._v(" "+_vm._s(order.displayName)+" ")]),_c('div',{staticClass:"d-flex caption onSurface_normal--text"},[_vm._v(" "+_vm._s(_vm.$moment(order.updatedAt).fromNow())+" ")])]),_c('div',{staticClass:"d-flex subtitle-2 font-weight-regular onSurface_dark--text"},[_vm._v(" "+_vm._s(order.purpose + " - " + order.file.length + "개 파일")+" ")])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }