<template>
  <div
    style="
      width: 100%;
      aspect-ratio: 4 / 3;
      border: 1px solid var(--v-background_dark-base);
      border-radius: 8px;
      overflow: hidden;
      position: relative;
    "
  >
    <v-progress-linear
      v-if="progress < 100 && file.url"
      :value="progress"
      height="2"
    />
    <div
      class="d-flex justify-center align-center"
      style="height: 100%; width: 100%"
      :style="isModelPreview && 'cursor: pointer'"
      @click="previewTo3d()"
    >
      <img
        v-if="isImage"
        :src="imageSrc"
        style="max-width: 100%; max-height: 100%; object-fit: contain"
      />
      <iframe
        v-if="isPDF"
        :src="pdfSrc"
        style="width: 100%; height: 100%; border: none"
      />
      <img
        v-if="isModelPreview"
        :src="imageSrc"
        style="max-width: 100%; max-height: 100%; object-fit: contain"
      />
      <div v-if="noPreview">지원하지 않는 미리보기</div>
      <div
        v-show="is3Dmodel"
        ref="rendererElement"
        style="height: 100%; width: 100%"
      ></div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { ThreeMFLoader } from "three/examples/jsm/loaders/3MFLoader";
// import occtimportjs from "occt-import-js";

export default {
  props: { file: Object },
  data() {
    return {
      previousFileUrl: null, // 이전 파일 URL 저장
      progress: 0, // 다운로드 또는 로딩의 진행 상황 (0 - 100)
      imageSrc: null, // 이미지 파일일 경우의 이미지 URL
      pdfSrc: null, // PDF 파일일 경우의 URL
      isImage: false, // 다운로드한 파일이 이미지인지 여부
      isPDF: false, // 다운로드한 파일이 PDF인지 여부
      isModelPreview: false,
      is3Dmodel: false,
      scene: null,
      camera: null,
      renderer: null,
      controls: null,
      cameraLight: null,
      xhr: null, // 현재 다운로드 중인 XMLHttpRequest
      animationId: null, // 애니메이션 루프 ID 저장
      isAnimating: false, // 애니메이션 활성 상태 플래그
      noPreview: false,
    };
  },
  watch: {
    file: {
      immediate: true,
      deep: true,
      handler(newFile) {
        if (
          (this.isModelPreview &&
            this.previousFileUrl !== newFile.previewImg) ||
          (!this.isModelPreview && this.previousFileUrl !== newFile.url)
        ) {
          // URL이 변경되었는지 확인
          this.cleanupRenderer(); // 이전 렌더러 중단
          this.imageSrc = null;
          this.pdfSrc = null;
          this.isImage = false;
          this.isPDF = false;
          this.isModelPreview = false;
          this.is3Dmodel = false;
          this.noPreview = false;
          this.progress = 0;
          if (newFile.url) {
            if (newFile.previewImg) {
              this.isModelPreview = true;
              this.previousFileUrl = newFile.previewImg; // 이전 파일 URL 업데이트
              this.downloadFile(newFile.previewImg); // 파일 로드
            } else if (["zip", "stp"].includes(newFile.type)) {
              this.noPreview = true;
              this.progress = 100;
            } else {
              this.previousFileUrl = newFile.url; // 이전 파일 URL 업데이트
              this.downloadFile(newFile.url); // 파일 로드
            }
          }
        }
      },
    },
  },
  methods: {
    previewTo3d() {
      if (this.isModelPreview) {
        this.downloadFile(this.file.url);
      }
    },
    downloadFile(url) {
      // 다운로드 요청 중단
      this.abortDownload();
      const xhr = new XMLHttpRequest();
      this.xhr = xhr; // 현재 요청 저장
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          this.progress = Math.round((event.loaded / event.total) * 100);
        }
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          this.handleFileLoad(blob);
          this.progress = 100;
        }
      };
      xhr.onerror = () => {
        this.progress = 0;
      };
      xhr.send();
    },
    abortDownload() {
      if (this.xhr) {
        this.xhr.abort(); // 현재 다운로드 중단
        this.xhr = null; // 참조 해제
      }
    },
    handleFileLoad(blob) {
      const fileType = blob.type;
      const fileName = this.file.name.toLowerCase();

      if (fileType.startsWith("image/")) {
        this.displayImage(blob);
      } else if (fileType === "application/pdf") {
        this.displayPDF(blob);
      } else if (fileName.endsWith(".stl")) {
        this.loadSTL(blob);
      } else if (fileName.endsWith(".obj")) {
        this.loadOBJ(blob);
      } else if (fileName.endsWith(".3mf")) {
        this.load3MF(blob);
      } else if (fileName.endsWith(".stp") || fileName.endsWith(".step")) {
        this.loadSTEP(blob);
      } else {
        console.error("Unsupported file format.");
      }
    },
    displayImage(blob) {
      this.isImage = !this.isModelPreview;
      this.isPDF = false;
      this.imageSrc = null;
      this.imageSrc = URL.createObjectURL(blob);
    },
    displayPDF(blob) {
      this.isImage = false;
      this.isPDF = true;
      this.pdfSrc = URL.createObjectURL(blob);
    },
    loadSTL(blob) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const loader = new STLLoader();
        const geometry = loader.parse(event.target.result);
        this.initializeScene();
        this.alignModel(geometry);
      };
      reader.readAsArrayBuffer(blob);
      this.isModelPreview = false;
      this.is3Dmodel = true;
    },
    loadOBJ(blob) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const loader = new OBJLoader();
        const object = loader.parse(event.target.result);

        this.initializeScene();
        object.traverse((child) => {
          if (child.isMesh) {
            this.alignModel(child.geometry);
          }
        });
      };
      reader.readAsText(blob);
      this.isModelPreview = false;
      this.is3Dmodel = true;
    },
    load3MF(blob) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const loader = new ThreeMFLoader();
        const object = loader.parse(event.target.result);
        this.initializeScene();
        object.children.forEach((child) => {
          child.children.forEach((mesh) => {
            if (mesh.isMesh) {
              this.alignModel(mesh.geometry);
            }
          });
        });
        this.animate();
      };
      reader.readAsArrayBuffer(blob);
      this.isModelPreview = false;
      this.is3Dmodel = true;
    },
    async loadSTEP() {
      // const wasmUrl =
      //   "https://cdn.jsdelivr.net/npm/occt-import-js@0.0.12/dist/occt-import-js.wasm";
      // const occt = await occtimportjs({
      //   locateFile: (name) => {
      //     console.log("name: ", name);
      //     return wasmUrl;
      //   },
      // });
      // console.log(occt);
    },
    alignModel(geometry) {
      geometry.rotateX(Math.PI / -2);
      geometry.computeBoundingBox();
      const boundingBox = geometry.boundingBox;
      const centerX = (boundingBox.max.x + boundingBox.min.x) / 2;
      const centerZ = (boundingBox.max.z + boundingBox.min.z) / 2;
      const minY = boundingBox.min.y;
      geometry.translate(-centerX, -minY, -centerZ);
      this.addModelToScene(geometry);
    },
    initializeScene() {
      this.cleanupRenderer(); // 기존 렌더러 정리
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0xffffff);
      const aspectRatio =
        this.$refs.rendererElement.offsetWidth /
        this.$refs.rendererElement.offsetHeight;
      this.camera = new THREE.PerspectiveCamera(60, aspectRatio, 10, 2000);
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(
        this.$refs.rendererElement.offsetWidth,
        this.$refs.rendererElement.offsetHeight
      );
      this.$refs.rendererElement.innerHTML = "";
      this.$refs.rendererElement.appendChild(this.renderer.domElement);
      const gridHelper = new THREE.GridHelper(300, 30);
      gridHelper.position.set(0, 0, 0);
      this.scene.add(gridHelper);
      this.addAxisHelpers();
      this.addBoundingBox();
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.isAnimating = true; // 애니메이션 활성화
      this.animate(); // 애니메이션 시작
    },
    addAxisHelpers() {
      const XaxisGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(-150, 0, 0),
        new THREE.Vector3(150, 0, 0),
      ]);
      const XaxisMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
      const XaxisLine = new THREE.Line(XaxisGeometry, XaxisMaterial);
      this.scene.add(XaxisLine);
      const YaxisGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 150, 0),
      ]);
      const YaxisMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff });
      const YaxisLine = new THREE.Line(YaxisGeometry, YaxisMaterial);
      this.scene.add(YaxisLine);
      const ZaxisGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, -150),
        new THREE.Vector3(0, 0, 150),
      ]);
      const ZaxisMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 });
      const ZaxisLine = new THREE.Line(ZaxisGeometry, ZaxisMaterial);
      this.scene.add(ZaxisLine);
    },
    addBoundingBox() {
      const maxDimensions = [300, 300, 200];
      const boxGeometry = new THREE.BoxGeometry(
        maxDimensions[0],
        maxDimensions[2],
        maxDimensions[1]
      );
      const wireframe = new THREE.EdgesGeometry(boxGeometry);
      const lineMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });
      const boundingBox = new THREE.LineSegments(wireframe, lineMaterial);
      boundingBox.position.set(0, 100, 0);
      this.scene.add(boundingBox);
    },
    addModelToScene(geometry) {
      const material = new THREE.MeshPhongMaterial({ color: 0xaecdff });
      const mesh = new THREE.Mesh(geometry, material);
      this.scene.add(mesh);
      const lightColor = 0xffffff;
      const topLight = new THREE.DirectionalLight(lightColor, 0.4);
      topLight.position.set(0, 100, 0);
      this.scene.add(topLight);
      this.cameraLight = new THREE.DirectionalLight(lightColor, 0.6);
      this.scene.add(this.cameraLight);
      const box = new THREE.Box3().setFromObject(mesh);
      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());
      const xyDistance = Math.max(size.x, size.z) * 0.7;
      const cameraX = center.x + xyDistance; // 우측 45도
      const cameraY = center.y + xyDistance + size.y / 2; // 위에서 45도
      const cameraZ = center.z + xyDistance; // Z 오프셋 추가
      this.camera.position.set(cameraX, cameraY, cameraZ);
      this.cameraLight.position.copy(this.camera.position);
      this.camera.near = xyDistance / 100;
      this.camera.far = xyDistance * 20;
      this.camera.updateProjectionMatrix();
      this.controls.target.set(0, size.y / 4, 0);
      this.controls.update();
      this.animate();
    },
    animate() {
      if (!this.isAnimating) return; // 애니메이션 중단

      this.animationId = requestAnimationFrame(this.animate);
      if (this.cameraLight && this.camera) {
        this.cameraLight.position.copy(this.camera.position); // 카메라 위치 동기화
      }
      if (this.renderer && this.scene && this.camera) {
        this.renderer.render(this.scene, this.camera);
      }
    },
    cleanupRenderer() {
      // 애니메이션 중단
      if (this.animationId) {
        cancelAnimationFrame(this.animationId);
        this.animationId = null;
      }
      this.isAnimating = false;

      if (this.renderer) {
        this.renderer.dispose();
        this.renderer.forceContextLoss();
        this.renderer = null;
      }
      if (this.scene) {
        this.scene.children.forEach((child) => {
          if (child.geometry) child.geometry.dispose();
          if (child.material) {
            if (Array.isArray(child.material)) {
              child.material.forEach((mat) => mat.dispose());
            } else {
              child.material.dispose();
            }
          }
        });
        this.scene = null;
      }
      this.camera = null;
      this.controls = null;
      this.cameraLight = null;
    },
  },
  beforeDestroy() {
    this.abortDownload(); // 컴포넌트 해제 시 다운로드 중단
    this.cleanupRenderer(); // 렌더러 정리
  },
};
</script>

<style scoped></style>
