<template>
  <div
    class="d-flex flex-column"
    style="
      min-width: 180px;
      gap: 12px;
      height: 100vh;
      border-right: 1px solid var(--v-background_dark-base);
      border-bottom: 1px solid var(--v-background_dark-base);
    "
  >
    <div
      class="d-flex justify-space-between px-3 py-4"
      style="border-bottom: 1px solid var(--v-background_dark-base)"
    >
      <div class="title-1 font-weight-bold onSurface_dark--text align-center">
        주문 관리
      </div>
      <v-btn icon x-small @click="$emit('update:search', true)">
        <feather type="search" size="20" />
      </v-btn>
    </div>
    <div
      class="d-flex flex-column px-2 pb-3"
      style="border-bottom: 1px solid var(--v-background_dark-base)"
    >
      <div v-for="item in status" :key="item.title">
        <v-hover v-slot="{ hover }" close-delay="10">
          <div
            class="d-flex px-3 justify-space-between align-center"
            @click="changeStatus(item.title)"
            style="height: 28px; border-radius: 4px"
            :style="
              (hover || filter.status == item.title) &&
              'background: var(--v-background_normal-base)'
            "
          >
            <div
              class="d-flex align-center subtitle-2 font-weight-bold"
              style="gap: 12px"
            >
              <feather size="18" :type="item.icon" /> {{ item.text }}
            </div>
            <div class="subtitle-2 font-weight-regular">
              {{
                orders.filter(
                  (v) =>
                    v.status == item.title &&
                    (filter.workspace == "전체 작업장" ||
                      v.workspace == filter.workspace)
                ).length
              }}
            </div>
          </div>
        </v-hover>
      </div>
    </div>
    <div class="d-flex flex-column px-2 pb-3 subtitle-2">
      <div v-for="item in workspace" :key="item">
        <v-hover v-slot="{ hover }" close-delay="10">
          <div
            class="px-3 align-center d-flex"
            style="height: 28px; border-radius: 4px"
            :style="
              (hover || filter.workspace == item) &&
              'background: var(--v-background_normal-base)'
            "
            @click="changeWorkspace(item)"
          >
            {{ item }}
          </div>
        </v-hover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-FilterSideBar",
  props: { orders: Array, filter: Object },
  data() {
    return {
      status: [
        { icon: "edit-3", title: "Draft", text: "접수 전" },
        { icon: "upload-cloud", title: "Pending", text: "신규 주문" },
        { icon: "user-check", title: "Checking", text: "확인 중" },
        { icon: "repeat", title: "Hold", text: "수정 요청" },
        { icon: "credit-card", title: "Ready", text: "결제 확인" },
        { icon: "tool", title: "Progress", text: "작업 진행" },
        { icon: "check-square", title: "Finished", text: "작업 완료" },
        { icon: "truck", title: "Shipped", text: "출고 완료" },
      ],
      workspace: [
        "전체 작업장",
        "모델링",
        "메이드올 대구지사",
        "경희대학교 국제캠퍼스",
      ],
    };
  },
  methods: {
    changeStatus(item) {
      if (this.filter.status == item) {
        return;
      }
      if (
        this.orders.filter(
          (v) =>
            v.status == item &&
            (this.filter.workspace == "전체 작업장" ||
              v.workspace == this.filter.workspace)
        ).length <= 0
      )
        return;
      this.filter.status = item;
      this.$emit("changeFilter", this.filter);
    },
    changeWorkspace(item) {
      if (this.filter.workspace == item) {
        return;
      }
      this.filter.workspace = item;
      this.$emit("changeFilter", this.filter);
    },
  },
};
</script>

<style></style>
