<template>
  <div
    class="d-flex px-5 justify-space-between align-center"
    style="
      width: 100%;
      height: 60px;
      border-bottom: 1px solid var(--v-background_dark-base);
    "
  >
    <div class="d-flex align-center" style="gap: 12px; width: 100%">
      <v-menu rounded bottom offset-y nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            small
            class="px-2 title-2 font-weight-regular onSurface_dark--text"
            min-width="100px"
            v-bind="attrs"
            v-on="on"
          >
            필터: {{ searchFilter.title }}
            <v-icon class="pl-2" size="20"> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in filter"
            :key="index"
            @click="
              () => {
                searchFilter = item;
                searchData = '';
              }
            "
          >
            <v-list-item-title>
              <span
                class="ml-2 subtitle-1 font-weight-regular onSurface--text"
                v-text="item.title"
              />
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <input
        class="font-weight-regular pa-2 d-flex align-center subtitle-1"
        type="text"
        style="width: 100%"
        v-model="searchData"
        @keypress.enter="searchOrder()"
        ref="searchInput"
      />
    </div>
    <v-btn icon small @click="$emit('update:search', false)">
      <feather type="x" size="20" />
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-SearchBar",
  props: {
    search: Boolean,
    searchedData: String,
    loading: Boolean,
    order: Object,
  },
  data() {
    return {
      filter: [
        { title: "이름", value: "displayName" },
        { title: "연락처", value: "phoneNumber" },
        { title: "uid", value: "uid" },
        { title: "Email", value: "email" },
        { title: "Oid", value: "id" },
      ],
      searchFilter: { title: "이름", value: "displayName" },
      searchData: "",
      searchedOrderList: [],
    };
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyUp);
    this.$refs.searchInput.focus();
    this.searchData = this.searchedData || "";
    if (this.$route.query.id && !this.order.id) {
      this.searchFilter = this.filter[4];
      this.searchData = this.$route.query.id;
      this.searchOrder();
      this.$router.push({
        path: this.$route.path,
        query: {},
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    searchOrder() {
      if (this.searchedData && this.searchedData == this.searchData) return;
      if (!this.searchData) return;
      this.$emit("update:loading", true);
      this.$emit("update:searchedData", this.searchData);
      this.searchedOrderList = [];
      const orderDB = this.$firebase
        .firestore()
        .collection("orders")
        .orderBy("createdAt", "desc");
      orderDB
        .where(this.searchFilter.value, "==", this.searchData)
        .limit(100)
        .get()
        .then((v) => {
          v.docChanges().forEach((change) => {
            const item = change.doc.data();
            if (item.createdAt) item.createdAt = item.createdAt.toDate();
            if (item.updatedAt) item.updatedAt = item.updatedAt.toDate();
            if (item.confirmedAt) item.confirmedAt = item.confirmedAt.toDate();
            if (item.finishedAt) item.finishedAt = item.finishedAt.toDate();
            this.searchedOrderList.push(item);
          });
          this.$emit("update:searchedList", this.searchedOrderList);
          this.$emit("update:loading", false);
        });
    },
    handleKeyUp(event) {
      if (event.key === "Escape") {
        this.$emit("update:search", false);
      }
    },
  },
};
</script>

<style></style>
