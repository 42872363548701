var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%","border-bottom":"1px solid var(--v-background_dark-base)","width":"100%","overflow-y":"auto"}},[_c('div',{staticClass:"d-flex pa-5 justify-space-between"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"4px"}},[_c('div',{staticClass:"title-1 font-weight-bold onSurface_dark--text"},[_vm._v(" "+_vm._s(_vm.order.displayName)+" ")]),_c('div',{staticClass:"subtitle-1 font-weight-regular onSurface_dark--text",staticStyle:{"white-space":"nowrap","width":"fit-content"}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.order.updatedAt,"YYYY-MM-DD hh:mm"))+" 수정된 주문 ")])]),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"24px"}},[_c('v-btn',{attrs:{"icon":"","x-small":"","disabled":!_vm.saveBtn,"loading":_vm.saveBtnLoading},on:{"click":_vm.saveOrder}},[_c('feather',{attrs:{"type":"save","stroke":_vm.saveBtn
              ? 'var(--v-onSurface_dark-base)'
              : 'var(--v-background_dark-base)',"size":"24"}})],1),_c('v-btn',{staticClass:"subtitle-1 font-weight-bold",attrs:{"color":"primary","max-height":"40","elevation":"0","loading":_vm.btnLoading},on:{"click":function($event){return _vm.btnClick()}}},[_vm._v(" "+_vm._s(_vm.order.status === "Pending" ? "상담 시작" : _vm.order.status === "Checking" ? "결제 확인" : _vm.order.status === "Ready" ? "작업 시작" : _vm.order.status === "Progress" ? "작업 완료" : _vm.order.status === "Finished" ? "출고 처리" : _vm.order.status === "Shipped" ? "상담 완료" : "메세지 전송")+" ")])],1)]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-column px-5 pb-10",staticStyle:{"gap":"20px","width":"100%"}},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"20px"}},[_c('customer-card-vue',{attrs:{"order":_vm.order}}),_c('payment-card-vue',{attrs:{"order":_vm.editedOrder},on:{"update":function($event){return _vm.updateValue($event)},"saveOrder":function($event){return _vm.saveOrder()}}})],1),(_vm.order.modelingFile?.length > 0)?_c('modeling-card-vue',{attrs:{"order":_vm.editedOrder}}):_vm._e(),_c('printing-card-vue',{attrs:{"order":_vm.editedOrder},on:{"update":function($event){return _vm.updateValue($event)},"saveOrder":function($event){return _vm.saveOrder()}}}),_c('result-card-vue',{attrs:{"order":_vm.editedOrder}})],1),_c('div',{staticClass:"d-flex flex-column pr-5 pb-10",staticStyle:{"gap":"20px","min-width":"360px","width":"380px"}},[_c('data-card-vue',{attrs:{"order":_vm.editedOrder},on:{"update":function($event){return _vm.updateValue($event)}}}),_c('chat-card-vue',{attrs:{"order":_vm.editedOrder}}),_c('message-card-vue',{attrs:{"order":_vm.editedOrder}}),_c('v-btn',{staticClass:"subtitle-1 font-weight-bold",attrs:{"color":"error","elevation":"0","loading":_vm.deleteBtnLoading},on:{"click":function($event){_vm.deleteBtnLoading = true;
          _vm.alert = {
            dialog: true,
            msg: '해당 기능은 되돌릴 수 없습니다. \n 주문서가 완전히 삭제됩니다.',
            confirmMsg: '삭제',
            cancelMsg: '취소',
            onConfirm: () => {
              _vm.$emit('deleteOrder', _vm.order);
              _vm.deleteBtnLoading = false;
            },
            onCancel: () => {
              _vm.deleteBtnLoading = false;
            },
          };}}},[_vm._v(" 주문서 영구 삭제 ")])],1)]),_c('WarningDialog',{attrs:{"msg":_vm.alert,"visible":_vm.alert.dialog},on:{"update:visible":function($event){return _vm.$set(_vm.alert, "dialog", $event)},"confirm":_vm.alert.onConfirm,"cancel":_vm.alert.onCancel}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }