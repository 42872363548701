<template>
  <div
    class="d-flex flex-column"
    style="
      min-width: 260px;
      max-width: 260px;
      gap: 12px;
      border-right: 1px solid var(--v-background_dark-base);
      border-bottom: 1px solid var(--v-background_dark-base);
      height: 100vh;
    "
  >
    <div
      class="d-flex px-3 py-4 justify-space-between"
      style="border-bottom: 1px solid var(--v-background_dark-base)"
      v-if="!search"
    >
      <div class="d-flex align-center" style="gap: 8px">
        <v-btn icon x-small @click="toggleSelectAll()">
          <feather
            :type="areAllSelected ? 'check-square' : 'square'"
            :stroke="
              areAllSelected
                ? 'var(--v-primary-base)'
                : 'var(--v-onSurface_dark-base)'
            "
          />
        </v-btn>
        <div class="title-2 font-weight-bold onSurface_dark--text d-flex">
          전체 선택
        </div>
      </div>
      <v-btn
        max-height="30px"
        min-width="54px"
        elevation="0"
        class="subtitle-2 font-weight-bold onSurface_normal--text px-2"
        @click="action()"
        :loading="btnLoading"
      >
        {{
          orders[0]?.status === "Pending"
            ? "상담 시작"
            : orders[0]?.status === "Checking"
            ? "결제 확인"
            : orders[0]?.status === "Ready"
            ? "작업 시작"
            : orders[0]?.status === "Progress"
            ? "작업 완료"
            : orders[0]?.status === "Finished"
            ? "출고 처리"
            : orders[0]?.status === "Shipped"
            ? "상담 완료"
            : "메세지 전송"
        }}
      </v-btn>
    </div>
    <div
      :class="search && 'pt-3'"
      class="d-flex flex-column px-2 pb-3"
      style="height: 100%; overflow: auto"
    >
      <div
        v-for="(order, index) in orders"
        :key="order.id"
        @click="
          () => {
            // 현재 경로와 쿼리의 id 값이 동일하지 않을 때만 작동
            if ($route.query.id !== order.id) {
              $router.push({
                path: $route.path,
                query: { id: order.id },
              });
            }
          }
        "
        class="d-flex pa-2 align-center"
        style="border-radius: 4px; gap: 8px"
        :style="
          selectedOrder.id == order.id &&
          'background: var(--v-background_normal-base)'
        "
      >
        <v-btn icon x-small @click="toggleSelect(order, index)">
          <feather
            :type="order.selected ? 'check-square' : 'square'"
            size="18"
            :stroke="
              order.selected
                ? 'var(--v-primary-base)'
                : 'var(--v-onSurface_dark-base)'
            "
          />
        </v-btn>
        <div class="d-flex flex-column" style="gap: 4px; width: 100%">
          <div class="d-flex justify-space-between">
            <div
              class="d-flex subtitle-2 font-weight-bold onSurface_dark--text"
            >
              {{ order.displayName }}
            </div>
            <div class="d-flex caption onSurface_normal--text">
              {{ $moment(order.updatedAt).fromNow() }}
            </div>
          </div>
          <div
            class="d-flex subtitle-2 font-weight-regular onSurface_dark--text"
          >
            {{ order.purpose + " - " + order.file.length + "개 파일" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ORDER from "@/API/order.js";
export default {
  name: "Admin-Order-ListSideBar",
  props: {
    orders: Array,
    selectedOrder: Object,
    filter: Object,
    search: Boolean,
  },
  computed: {
    areAllSelected() {
      return this.orders.length && this.orders.every((order) => order.selected); // 모든 order가 selected인지 확인
    },
  },
  data() {
    return {
      btnLoading: false,
    };
  },
  methods: {
    toggleSelectAll() {
      const selectAll = !this.areAllSelected; // 모두 선택되어 있으면 해제, 아니면 선택
      this.orders.forEach((order, index) => {
        order.selected = selectAll;
        this.$set(this.orders, index, order);
      });
    },
    toggleSelect(order, index) {
      order.selected = !order.selected;
      this.$set(this.orders, index, order);
    },
    action() {
      if (!this.orders.some((order) => order.selected === true))
        return this.$toasted.global.error("선택된 주문이 없습니다.");
      this.btnLoading = true;
      this.orders
        .filter((v) => v.selected)
        .forEach(async (o) => {
          if (o.status === "Pending") {
            try {
              const response = await this.$axios.post(
                "notice/orderCheckStart",
                {
                  order: o,
                  admin: this.$store.getters["auth/user/GET_USER"].displayName,
                }
              );
              // 메시지 발송이 성공했을 때만 안내 문구를 표시
              if (response.status === 200) {
                this.$toasted.global.notice(
                  "고객에게 상담 시작 메세지가 전송되었습니다."
                );
                o.status = "Checking";
                await ORDER.updateOrder(o);
              } else {
                this.$toasted.global.error(
                  "메세지 전송에 실패했습니다. 다시 시도해주세요."
                );
              }
            } catch (error) {
              console.error("메시지 발송 중 오류:", error);
              this.$toasted.global.error(
                "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
              );
            }
          } else if (o.status === "Checking") {
            try {
              if (!o.paymentMethod)
                return this.$toasted.global.error(
                  "결제 방식을 먼저 선택해 주세요!"
                );
              if (!o.shippingMethod)
                return this.$toasted.global.error(
                  "배송 방식을 먼저 선택해 주세요!"
                );
              await this.$axios.post("notice/orderPaid", o);
              o.status = "Ready";
              o.paidAt = new Date();
              await ORDER.updateOrder(o);
            } catch (error) {
              console.error("메시지 발송 중 오류:", error);
              this.$toasted.global.error(
                "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
              );
            }
          } else if (o.status === "Ready") {
            try {
              await this.$axios.post("/notice/orderStart", o);
              this.$toasted.global.notice(
                "고객님께 작업 시작 메세지가 전송되었습니다"
              );
              o.status = "Progress";
              await ORDER.updateOrder(o);
            } catch (error) {
              console.error("메시지 발송 중 오류:", error);
              this.$toasted.global.error(
                "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
              );
            }
          } else if (o.status === "Progress") {
            try {
              await this.$axios.post("/notice/orderFinished", o);
              this.$toasted.global.notice(
                "고객님께 완료 안내 메세지가 전송되었습니다"
              );
              o.status = "Finished";
              await ORDER.updateOrder(o);
            } catch (error) {
              console.error("메시지 발송 중 오류:", error);
              this.$toasted.global.error(
                "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
              );
            }
          } else if (o.status === "Finished") {
            try {
              if (
                o.shippingMethod == "택배" &&
                (o.paymentMethod == "현금 결제" ||
                  o.paymentMethod == "세금계산서") &&
                !o.trackingCode
              )
                return this.$toasted.global.error("운송장 번호가 없습니다.");
              await this.$axios.post("/notice/orderShipped", o);
              this.$toasted.global.notice(
                "고객님께 출고 안내 메세지가 전송되었습니다"
              );
              o.status = "Shipped";
              o.shipAt = new Date();
              await ORDER.updateOrder(o);
            } catch (error) {
              console.error("메시지 발송 중 오류:", error);
              this.$toasted.global.error(
                "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
              );
            }
          } else if (o.status === "Shipped") {
            try {
              this.sendReviewRequest = true;
              await this.$axios.post("notice/orderComplete", {
                order: o,
                sendReviewRequest: this.sendReviewRequest,
              });
              o.status = "Completed";
              await ORDER.updateOrder(o);
            } catch (error) {
              console.error("메시지 발송 중 오류:", error);
              this.$toasted.global.error(
                "메세지 전송 중 오류가 발생했습니다. 다시 시도해주세요."
              );
            }
          }
        });
      this.btnLoading = false;
    },
  },
};
</script>

<style></style>
